import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import { GetClients } from '../Functions/ListerClients';

function TableauClient() {

    const [clients,setClient] = useState([])


    useEffect(()=>{
        
       const fetchClients = async()=>{
            setClient(await GetClients())
       }

       fetchClients()
    },[])

  return (
    <div className='mt-5'>
        <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Proprio</th>
          <th>Animal</th>
          <th>Num Tel</th>
        </tr>
      </thead>
      <tbody>
       {clients.map((client, index)=>(
         <tr key={index}>
        <td>{index}</td>
         <td>{client.nom_proprio}</td>
         <td>{client.nom}</td>
         <td>{client.num_tel}</td>
       </tr>
       ))}
      </tbody>
    </Table>
    </div>
  )
}

export default TableauClient
