import React from 'react'
import { Button } from 'react-bootstrap'

const DashboardBtns = () => {
  return (
    <div className='d-flex flex-row justify-content-center mt-5'>
        <Button className='mx-2' onClick={()=>{
          window.location.href = '/fiches'
        }}>Créé fiche</Button>
        <Button variant='danger' className='mx-2'
        onClick={()=>{
          window.location.href = '/CreeFacture'
        }}
        >Créé facture</Button>
        <Button variant='success' className='mx-2'
        onClick={()=>{
          window.location.href = '/clients'
        }}
        >Lister les Clients</Button>
       </div>
  )
}

export default DashboardBtns
