import React from 'react'
import TableauClient from '../Components/TableauClient'
import NavBar from '../Components/NavBar'
import AdminNavBar from '../Components/AdminNavBar'

function ListeDesClients() {
  return (
    <div>
        <NavBar/>
        <AdminNavBar/>
      <TableauClient />
    </div>
  )
}

export default ListeDesClients
