import axiosCSRF from '../Axios/axiosConfig';

export const GetClients = async()=>{
  
    const token = localStorage.getItem('token')

   const response = await axiosCSRF.get('/client', { 
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })


    return response.data.data

}